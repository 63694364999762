.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #f0f0f0;
  color: #333;
  min-height: 100vh; /* Ensure the container fills the viewport height */
  position: relative;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #2a9d8f;
  animation: fadeIn 2s;
}

.message {
  font-size: 1.2rem;
  color: #e76f51;
  animation: fadeIn 3s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  background-color: #333;
  color: white;
}

.notification-bar {
  background-color: #f8f9fa; /* A light gray background */
  color: #343a40; /* Dark text for contrast */
  text-align: center;
  padding: 15px 0;
  border-top: 1px solid #e0e0e0; /* Add a subtle border for definition */
  border-bottom: 1px solid #e0e0e0;
  font-size: 1.1rem;
  font-weight: bold;
}

.under-construction-image {
  max-width: 60%; /* Adjust this value as needed */
  max-height: 500px; /* Adjust this value as needed */
  height: auto; /* Maintain aspect ratio */
  display: block; /* To center the image if desired */
  margin: 20px auto; /* Add margin and auto for horizontal centering */
}